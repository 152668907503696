import React, { Component, createContext } from "react";
import { AuthResponse } from "@cnri/cordra-client";
import { AuthContextInfo, AuthStoreState as State } from "./interfaces";

import {
    getObjectById,
    authenticate,
    getSession,
    signOut,
    resetPasswordWithKey
} from "../services/MODSService";

const defaultValue: AuthContextInfo = { loggedIn: false };

const AuthContext = createContext<AuthContextInfo>(defaultValue);

class AuthStore extends Component<State> {
    state: State = {
        loggedIn: false
    };

    checkLogin = async (): Promise<boolean> => {
        return getSession()
        .then(({ active: loggedIn, userId }: AuthResponse) => {
            if (loggedIn && userId) {
                getObjectById({ id: userId })
                    .then(data => {
                        const user = data.content;
                        this.setState({ user, loggedIn });
                    })
                    .catch(response => {
                        this.setState({ loggedIn: false });
                    });
            } else {
                this.setState({ loggedIn: false });
            }
            return loggedIn;
        })
        .catch(response => {
            this.setState({ loggedIn: false });
            return false;
        });
    };

    getStore = (): State => {
        return this.state;
    };

    login = async (username: string, password: string): Promise<void> => {
        return authenticate(username, password)
            .then(
                ({ active: loggedIn, userId }) => {
                    loggedIn && getObjectById({ id: userId! })
                        .then(data => {
                            const user = {
                                name: username,
                                id: userId,
                                isAdmin: Boolean(data && data.content && data.content.admin),
                                isGuest: Boolean(data.content.guest),
                                providers: data && data.content && data.content.providers,
                            };
                            this.setState({ user, loggedIn: true });
                        });
                }
            );
    };

    logout = async (): Promise<void> => {
        return signOut()
            .then(() => {
                this.setState({ loggedIn: false });
            })
            .catch(err => {});
    };

    reset = async (username: string):Promise<void> => {
        return resetPasswordWithKey(username)
            .then(() => {
                this.setState({ loggedIn: false });
            })
            .catch(err => {});
    };

    render(): JSX.Element {
        return (
            <React.Fragment>
                <AuthContext.Provider
                    value={{
                        ...this.state,
                        getStore: this.getStore,
                        login: this.login,
                        checkLogin: this.checkLogin,
                        logout: this.logout,
                        reset: this.reset
                    }}
                >
                    {this.props.children}
                </AuthContext.Provider>
            </React.Fragment>
        );
    }
}

export { AuthStore, AuthContext };
