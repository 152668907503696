import React from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import _ from "lodash";
import { DataFetchingStateX2 } from "../common/hoc/WithDataFetching";
import { ActivationKeyParams } from "./ExtractActivationKey";
import Form from "../common/components/Form";

interface Props extends DataFetchingStateX2<{}, CordraObject> {
    injected: boolean;
    activationProps: ActivationKeyParams;
}

interface ViewModel {
    id: string;
    emailAddress: string;
    activationKey:string;
    password:string;
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class Reset extends Form<Props> {

    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        emailAddress: Joi.string()
            .required()
            .label("Email Address"),
        activationKey: Joi.string()
            .required()
            .min(1)
            .label("Activation Key"),
        password: Joi.string()
            .required()
            .min(8)
            .label("Password")
    };

    state: State = {
        data: {
            id: "",
            emailAddress: "",
            activationKey: "",
            password: ""
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (props: Props): void => {
        const { activationProps } = props;
        this.setState({
            data: {
                id: activationProps.id,
                emailAddress: activationProps.emailAddress,
                activationKey: activationProps.activationKey,
                password: ""
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        this.mapToViewModel(this.props);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const data = {
            id: viewModel.id,
            content: {
                password: viewModel.password,
                activationKey: viewModel.activationKey,
                emailAddress: viewModel.emailAddress,
                newAccount: false
            }
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
            .then(({ id }) => {
                this.setState({ saved: true, data: { id } }, () => {
                    toast.success("Successfully updated the password.");
                });
            })
            .catch(({ message, status }) => {
                toast.error("Something went wrong when updating the password.");
                this.setState({ errors: { error: "Error" } });
            });
        });
    };

    render = (): JSX.Element => {
        const { errors, saved, saveOngoing, mapped } = this.state;
        const waitUntilMapped = this.props.shouldFetch || this.props.injected;
        // return errors && !_.isEmpty(errors) ? (
        //     <Redirect to="/not-found" />
        // ) :
        return saved ? (
            <Redirect to={`/login`} />
        ) : (mapped || !waitUntilMapped) ? (
            <div className="container p-5">
                <form onSubmit={this.handleSubmit}>
                    <div className="card border border-primary">
                        <div className="card-header bg-primary text-white text-center">
                            <h2 className="bg-primary text-white text-center">
                                <i className="fa fa-lock fa-2x mt-1" />
                            </h2>
                            <h3 className="bg-primary text-white text-center py-4 mt-n3">
                                Reset Your Password
                            </h3>
                        </div>
                        <div className="card-body p-3">
                            {this.renderInput("emailAddress", "Email Address")}
                            {this.renderInput("password", "Password", "password")}
                            {this.renderInput("activationKey", "Activation Key")}
                            <div className="pt-2">
                                {saveOngoing
                                    ? this.renderButton("Resetting your password", "fa fa-spinner fa-spin")
                                    : this.renderButton("Reset your password", "fa fa-unlock")}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        ) : <React.Fragment></React.Fragment>;
    };
}

export default Reset;
