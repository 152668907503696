import { compose } from "redux";

import Activate from "./Activate";
import Default from "./Default";
import Error from "../Error";
import { withContainerX2 } from "../common/hoc/WithContainer";
import extractActivationKey from "./ExtractActivationKey";

import { activateMember } from "../../services/MODSService";
import injectAuthStoreAsProps from "../InjectAuthStoreAsProps";

export default compose(
    injectAuthStoreAsProps(),
    extractActivationKey(),
    withContainerX2({
        dataParams: {
            fetchHandler: () => Promise.resolve({}),
            saveHandler: activateMember
        },
        Error,
        DefaultComponent: Default
    })
// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(Activate) as React.FunctionComponent<any>;
