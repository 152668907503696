import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotAuthorized from "./NotAuthorized";
import NotFound from "./NotFound";
import PrivacyStatement from "./PrivacyStatement";
import TermsAndConditions from "./TermsAndConditions";
import ProtectedRoute from "./common/components/ProtectedRoute";
import { AuthContext } from "./AuthStore";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import Logout from "./Logout";
import ActivateContainer from "./Member/ActivateContainer";
import ResetContainer from "./Member/ResetContainer";
import protectedRoutesTable from "./ProtectedRoutesTable";
import { getStartedPostId, getStartedGuestPostId } from "../services/MODSService";

const Routes = (): JSX.Element => {
    const authStore = useContext(AuthContext);
    const { user } = authStore;
    return (
        <Switch>
            {protectedRoutesTable.map(({ path, component, exact, disableAccessToGuest }, j) => {
                if (disableAccessToGuest && user && user.isGuest) {
                    return (<Route path="/not-found" component={NotFound} />);
                } else {
                    return (<ProtectedRoute key={j} component={component} path={path} exact={exact} />);
                }
            })}
            <Route render={props => <LoginForm {...props} authStore={authStore} />} path="/login" exact />
            <Route render={props => (<ResetPasswordForm {...props} authStore={authStore} />)} path="/reset-password" exact />
            <Route render={props => <Logout {...props} authStore={authStore} />} path="/logout" exact />
            <Route render={props => (<ActivateContainer {...props} />)} path="/activate/:prefix/:id" exact />
            <Route render={props => (<ResetContainer {...props} />)} path="/reset/:prefix/:id" exact />
            <Route path="/not-found" component={NotFound} />

            <Route path="/not-authorized" component={NotAuthorized} />
            <Route path="/privacy-statement" component={PrivacyStatement} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Redirect from="/get-started" exact to={`posts/${getStartedPostId}`} />
            <Redirect from="/get-started-guest" exact to={`posts/${getStartedGuestPostId}`} />
            <Redirect from="/" exact to="/posts" />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
