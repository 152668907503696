import _ from "lodash";
import { AuthResponse, CordraClient, CordraObject, SearchResults, ClientConfig } from "@cnri/cordra-client";
import { handleError } from "../components/util";

export const cordraUrl: string = process.env.REACT_APP_API_URL || "";
export const handlePrefix = process.env.REACT_APP_HANDLE_PREFIX;
export const hdlProxyUrl: string = process.env.REACT_APP_HDL_PROXY_URL || "";

export const getStartedPostId = `${handlePrefix}/010b85ad56b34c34c7c2`;
export const getStartedGuestPostId = `${handlePrefix}/d3fca45477b33b931a13`;

const CLIENT_AUTH_EXPIRATION: number = 365 * 24 * 60 * 60 * 1000;
const clientConfig: ClientConfig = {
    authTokenExpirationMs: CLIENT_AUTH_EXPIRATION
};
let client: CordraClient = new CordraClient(cordraUrl, undefined, clientConfig);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const retrieveCordraOptions = (): { username: string; access_token: string } => {
    const cordraOptions: string | null = localStorage.getItem("cordraOptions");
    return cordraOptions && JSON.parse(cordraOptions) || {};
};

export const storeCordraOptions = (options: { username: string; access_token: string }): void => {
    deleteCordraOptions();
    localStorage.setItem("cordraOptions", JSON.stringify(options));
};

export const deleteCordraOptions = (): void => {
    localStorage.removeItem("cordraOptions");
};

export const authenticate = (username: string, password: string): Promise<AuthResponse> => {
    const options = {
        username,
        password
    };
    return client.authenticate(options).then((response: AuthResponse) => {
        const { active } = response;
        if (active) {
            const optionsWithToken = {
                username,
                access_token: response.access_token || ""
            };
            storeCordraOptions(optionsWithToken);
            client = new CordraClient(cordraUrl, retrieveCordraOptions(), clientConfig);
        } else {
            client = new CordraClient(cordraUrl, undefined, clientConfig);
        }
        return response;
    }).catch(response => {
        handleError(response);
        return response;
    });
};

export const getSession = (): Promise<AuthResponse> => {
    client = new CordraClient(cordraUrl, retrieveCordraOptions());
    return client.getAuthenticationStatus().then((response: AuthResponse) => {
        if (response && response.active) {
            client = new CordraClient(cordraUrl, retrieveCordraOptions(), clientConfig);
        }
        return response;
    }).catch(response => {
        client = new CordraClient(cordraUrl, retrieveCordraOptions(), clientConfig);
        handleError(response);
        return response;
    });
};

export const isSessionActive = async (): Promise<void> => {
    client = new CordraClient(cordraUrl, retrieveCordraOptions());
    await client.getAuthenticationStatus().then((response: AuthResponse) => {
        if (response && response.active) {
            client = new CordraClient(cordraUrl, retrieveCordraOptions(), clientConfig);
        } else {
            const error = {
                status: 401
            };
            throw error;
        }
        return response;
    }).catch(response => {
        handleError(response);
    });
};

export const signOut = (): Promise<AuthResponse> => {
    return client.signOut().then(_ => {
        deleteCordraOptions();
        client = new CordraClient(cordraUrl);
    }).catch(response => {
        handleError(response);
        return response;
    });
};

export const getObjectsByType = ({ type, pageNum, pageSize, sortFields }: { type: string; pageNum: number; pageSize: number; sortFields: [] }): Promise<SearchResults<CordraObject>> => {
    return isSessionActive().then(() => {
        return client.search(`+type:${type}`, {
            pageNum,
            pageSize,
            sortFields
        });
    });
};

export const search = ({ query, pageNum, pageSize, sortFields }: { query: string; pageNum: number; pageSize: number; sortFields: [] }): Promise<SearchResults<CordraObject>> => {
    return isSessionActive().then(() => {
        return client.search(query, {
            pageNum,
            pageSize,
            sortFields
        });
    });
};

export const getObjectById = ({ id }: { id: string }): Promise<CordraObject> => {
    return client.get(id);
};

export const getPayloadDownloadLink = (id: string, payloadName: string): string => {
    const cordraOptions = retrieveCordraOptions();
    return client.getPayloadDownloadLink(id, payloadName) + "&access_token=" + cordraOptions.access_token;
};

export const create = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object);
};

export const update = (object: CordraObject): Promise<CordraObject> => {
    return client.update(object);
};

export const createProvider = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object).then(async (providerObj: CordraObject) => {
        await client.callMethod(
            `${handlePrefix}/ffeed47b5a6fb7eb7a2c`,
            "updateAllProviders",
            null
        );
        return providerObj;
    });
};

export const updateProvider = (object: CordraObject): Promise<CordraObject> => {
    return client.update(object).then(async (providerObj: CordraObject) => {
        await client.callMethod(
            `${handlePrefix}/ffeed47b5a6fb7eb7a2c`,
            "updateAllProviders",
            null
        );
        return providerObj;
    });
};

export const createWG = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object).then(async (wgObject : CordraObject) => {
        await client.callMethod(
            `${handlePrefix}/7ead7adf039944acdde4`,
            "updateAllWGChairs",
            null
        );
        return wgObject;
    });
};

export const updateWG = (object: CordraObject): Promise<CordraObject> => {
    return client.update(object).then(async (wgObject : CordraObject) => {
        await client.callMethod(
            `${handlePrefix}/7ead7adf039944acdde4`,
            "updateAllWGChairs",
            null
        );
        return wgObject;

    });
};

export const createMember = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object).then(async (memberObject: CordraObject) => {
        if (memberObject.content.guest) {
            await client.callMethod(
                `${handlePrefix}/87eb34c4aeba63902054`,
                "updateAllGuests",
                null
            );
        } else {
            await client.callMethod(
                `${handlePrefix}/6a72085653e4c5be8c76`,
                "updateAll",
                null
            );
            await client.callMethod(
                `${handlePrefix}/a10df6f24adf67bd97f7`,
                "updateAdminGroup",
                null
            );
        }
        return generateAndSendActivationKey(memberObject.id!).then(_object => {
            return memberObject;
        });
    });
};

export const updateMember = (object: CordraObject): Promise<CordraObject> => {
    return client.update(object).then(async (memberObject: CordraObject) => {
        await client.callMethod(
            `${handlePrefix}/87eb34c4aeba63902054`,
            "updateAllGuests",
            null
        );
        await client.callMethod(
            `${handlePrefix}/6a72085653e4c5be8c76`,
            "updateAll",
            null
        );
        await client.callMethod(
            `${handlePrefix}/a10df6f24adf67bd97f7`,
            "updateAdminGroup",
            null
        );
        return memberObject;
    });
};

export const createPost = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object).then(async (postObject: CordraObject) => {
        await sendNotificationEmail(postObject, {});
        await updateTagSet(postObject);
        return postObject;
    });
};

export const updatePost = (object: CordraObject, previousObject?: CordraObject): Promise<CordraObject> => {
    return client.update(object).then(async (postObject: CordraObject) => {
        if (postObject.content.to !== (previousObject && previousObject.content.to)) {
            const tos = _.differenceWith(postObject.content.to, previousObject!.content.to, _.isEqual);
            const params = { updatedTos: tos };
            await sendNotificationEmail(postObject, params);
        }
        if (postObject.content.tags) {
            try {
                await updateTagSet(postObject);
            } catch (error) {
                console.log("Error occurred while updating the tag object", error);
            }
        }
        return postObject;
    });
};


const sendNotificationEmail = async (postObject: CordraObject, params: {}) => {
    // Send a notification email
    await client.callMethod(
        postObject.id!,
        "sendNotificationEmail", params
    );
};

const updateTagSet = async (postObject: CordraObject): Promise<void> => {
    const resultsObj = (await client.search("+(type:TagSet)")).results;
    if (resultsObj.length === 1) {
        const tagsObject = resultsObj[0];
        await client.callMethod(tagsObject.id!, "addTag", { tags: postObject.content.tags });
    }
};

export const searchPostsByTag = async (tag: string): Promise<SearchResults<CordraObject>> => {
    return client.callMethodForType("Post", "searchPostsByTag", { tag });
};


export const retrieveTagsObject = async (): Promise<CordraObject> => {
    return client.callMethodForType("TagSet", "getTagSet", {})
    .then((response) => {
        return response;
    });
};

export const createProviderPost = (object: CordraObject): Promise<CordraObject> => {
    return client.create(object).then(async (providerPostObject: CordraObject) => {
        await client.callMethod(
            providerPostObject.id!,
            "sendNotificationEmail",
            providerPostObject.content
        );
        return providerPostObject;
    });
};

export const activateMember = (object: CordraObject): Promise<CordraObject> => {
    const defaultOptions = {
        username: "",
        password: ""
    };
    const cordraClient = new CordraClient(cordraUrl, defaultOptions);
    return cordraClient
        .callMethod(object.id!, "activateMember", object.content);
};

export const generateAndSendActivationKey = (id: string): Promise<CordraObject> => {
    const defaultOptions = {
        username: "",
        password: ""
    };
    const cordraClient = new CordraClient(cordraUrl, defaultOptions);
    return cordraClient
        .callMethod(id, "generateAndSendActivationKey", null);
};

export const resetPasswordWithKey = (username: string): Promise<CordraObject> => {
    const defaultOptions = {
        username: "",
        password: ""
    };
    const cordraClient = new CordraClient(cordraUrl, defaultOptions);
    return cordraClient
        .callMethodForType("Member", "getMemberFromUsername", {
            username
        })
        .then(async (object: CordraObject) => {
            await client.callMethod(object.id!, "resetPasswordWithKey", null);
            return object;
        });
};
